import type { ReactNode } from 'react';
import type { ParagraphMeta } from '../../../interfaces';

export type ModalType = ParagraphMeta & {
  type: 'modal';
  field_placeholder_id: string;
  field_placeholder_text: string;
  field_headline?: string;
  field_html_editor?: string;
};

export enum ModalTestIds {
  main = 'dialog-wrapper',
  title = 'modal-title',
  content = 'modal-content',
  closeButton = 'close-iconbutton',
}

export type ModalProps = {
  /** Handles open state */
  openState: { isOpen: boolean; setIsOpen: React.Dispatch<React.SetStateAction<boolean>> };
  /** Title to display at the top of the dialog */
  title: string;
  /** Dialog content */
  children?: ReactNode;
};
