import type { CustomerDataWithStatus } from '@packages/utilities/src/useCustomer/useCustomer';
import * as yup from 'yup';
import type { IntlShape } from 'react-intl';
import type { ConvertedFormNLDataType } from '../../GeneralForm/helpers/handleSubmit';
import type { FormValues } from '../../types';
import { InputIDs } from '../../types';
import { noSpecCharsNoNumbers } from '../../helpers/validationSchema';
import { formMessages, specialErrorMessages } from '../../messages';

/**
 * Fields that are used in the form
 */
export const initialFieldValues: FormValues = {
  ...Object.fromEntries(
    [InputIDs.FIRSTNAME, InputIDs.LASTNAME, InputIDs.EMAIL].map((k) => [k, '']),
  ),
  [InputIDs.SALUTATION]: 'notSpecified',
};

/**
 * Fields that should be prefilled with user's data
 */
export const toPrefill = [
  InputIDs.FIRSTNAME,
  InputIDs.LASTNAME,
  InputIDs.SALUTATION,
  InputIDs.EMAIL,
];

/* Reference of Post Body:
  ------------------------------------------------------
  {
    "emailAddress": "mymail@example.com",
    "firstName": "MyFirstname",
    "lastName": "MyLastName",
    "salutation": "female",
    "areaKey": "serviceHilfe",
  }
  */
export const convertFormDataToPostData = (
  formValues: FormValues,
  areaKey: string,
): ConvertedFormNLDataType => {
  const salutation = formValues[InputIDs.SALUTATION];
  return {
    emailAddress: formValues[InputIDs.EMAIL] || '',
    firstName: formValues[InputIDs.FIRSTNAME] || '',
    lastName: formValues[InputIDs.LASTNAME] || '',
    ...(salutation &&
      salutation !== 'notSpecified' && {
        salutation,
      }),
    areaKey,
  };
};

const genderSalutationMap = {
  m: 'male',
  f: 'female',
  d: 'notSpecified',
};
export const getDefaultValues = (customer: CustomerDataWithStatus | undefined): FormValues => ({
  [`${InputIDs.FIRSTNAME}`]: customer?.firstName || '',
  [`${InputIDs.LASTNAME}`]: customer?.lastName || '',
  [`${InputIDs.EMAIL}`]: customer?.email || '',
  [`${InputIDs.SALUTATION}`]: customer?.gender && genderSalutationMap[customer.gender],
});

export const newsletterSubscribeFormSchema = (intl: IntlShape) =>
  yup.object().shape({
    [InputIDs.FIRSTNAME]: yup
      .string()
      .matches(noSpecCharsNoNumbers, intl.formatMessage(specialErrorMessages.noSpecCharsNoNumbers)),
    [InputIDs.LASTNAME]: yup
      .string()
      .matches(noSpecCharsNoNumbers, intl.formatMessage(specialErrorMessages.noSpecCharsNoNumbers)),
    [InputIDs.EMAIL]: yup
      .string()
      .email(intl.formatMessage(formMessages[InputIDs.EMAIL].error.valid))
      .required(intl.formatMessage(formMessages[InputIDs.EMAIL].error)),
  });
