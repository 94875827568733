import DOMPurify from 'isomorphic-dompurify';
import { FormattedMessage } from 'react-intl';
import { useI18n } from '@packages/shared';
import { useConfig } from '@packages/utilities';
import { voucherModalMessages } from './helpers/messages';

export const Page1FormModalContent = ({ conditionsText }: { conditionsText?: string }) => {
  const { language } = useI18n();
  const {
    host: { domain },
    forms: { newsletterSubscribe },
  } = useConfig();

  const { voucherLink } = newsletterSubscribe[language];

  if (conditionsText) {
    return (
      <span
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(conditionsText),
        }}
      />
    );
  }

  return (
    <FormattedMessage
      id={voucherModalMessages.content.id}
      defaultMessage={voucherModalMessages.content.defaultMessage}
      values={{
        aDomain: (chunks) => <a href={`https://${domain}`}>{chunks}</a>,
        aVoucher: (chunks) => <a href={voucherLink}>{chunks}</a>,
        h5: (chunks) => <h5>{chunks}</h5>,
      }}
    />
  );
};
